@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}
