
.box{
    height:22vh;
    width:60vw;
    margin-top:1em;
    padding-top:0.1em;
    padding-left: 0.3rem;
    background-color: #ffffff;
    text-align: center;
    font-weight: bold;
    color: #757373;
    margin-bottom: 0.2em;} 

    .dollar-sign {
        font-size: 4em !important;
        font-weight: bold; /* Remove single quotes */
        color: #2c98a4;
      }
      
      @media (max-width:576px) {
        .containerBox{
            height: 100vh;
        }
        .box{
            width:75vw;
            height:21vh;
        }
        .dollar-sign {
          font-size:6.2em !important;
        }
      }
      .ReactFlagsSelect-module_selectOptions__3LNBJ{
        background-color:#535353 !important;
        
    }
    
    .ReactFlagsSelect-module_selectOptions__3LNBJ :hover{
        background-color:#9e9e9e !important;
    }
      

